<template>
  <div>
    <div class="workplatform-title m-b-10">基础信息</div>
    <Row class="p-b-5" :gutter="8">
      <i-col span="8" style="word-break: break-all;">
          <label class="title">标题</label>
          {{noticeBean.title}}
      </i-col>
      <i-col span="8">
          <label class="title">创建者 </label>
          {{noticeBean.createUserName}}
      </i-col>
      <i-col span="8">
          <label class="title">创建时间 </label>
          {{noticeBean.createTime}}
      </i-col>
    </Row>
    <Row class="p-b-5" :gutter="8">
      <i-col span="24" style="word-break: break-all;">
          <label class="title">通知内容</label>
          {{noticeBean.content}}
      </i-col>
    </Row>

    <div class="workplatform-title m-t-20 m-b-10">上传文件清单</div>
    <i-table stripe  :columns="approvalFileColumn" :data="approvalFile" size="small"></i-table>

    <div class="workplatform-title m-t-20 m-b-10">发送人员清单</div>
    <i-table stripe  :columns="sendUsersColumn" :data="sendUsersData" size="small"></i-table>

  </div>
</template>

<script>
import { formatEnable } from '@/utils/tagStatus'
import { checkNotice, getNoticeFiles, getNoticeUsers } from '@/api/sys/notice'

export default {
  data () {
    return {
      noticeBean: {},

      approvalFile: [],
      approvalFileColumn: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 28, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/WinWordLogoSmall.scale-180.png'), height: '30px' } })
            } else if (this.isMSExcel(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/ExcelLogoSmall.scale-180.png'), height: '30px' } })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        {
          title: '下载',
          render: (h, params) => {
            return h('a',
              {
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }
              },
              '下载'
            )
          }
        }
      ],

      sendUsersColumn: [
        { title: '所属公司', key: 'companyName' },
        { title: '接收人', key: 'userName' },
        {
          title: '是否发送成功',
          align: 'center',
          render: (h, params) => {
            return formatEnable(h, params.row.status === 2)
          }
        },
        { title: '查看时间', key: 'checkTime' }
      ],
      sendUsersData: []
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const queryModel = {
        noticeId: this.detailId
      }

      checkNotice(queryModel).then(res => {
        this.noticeBean = res
      })

      getNoticeFiles(queryModel).then(res => {
        this.approvalFile = res
      })

      getNoticeUsers(queryModel).then(res => {
        this.sendUsersData = res
      })
    },
    downloadFile (file) {
      window.location.href = 'https://' + file.bucket + '.oss-cn-shenzhen.aliyuncs.com/' + file.filePath
    },
    isMSWord (file) {
      const mimeTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      return mimeTypes.includes(file.mimeType)
    }
  },
  computed: {
    detailId () {
      return this.$store.state.approvalCreate.detailId
    }
  }
}
</script>
